import Component from '../../classes/Component';
import NodeEmitter from '../../classes/NodeEmitter';

import each from 'lodash/each';

import { insertAfter } from '../../utils/utils';
import Mdesign from './Mdesign';

export default class DesignProcessSection extends Component {
	constructor() {
		super({
			element: '.process',
			elements: {
				items: '.process__item',
				steps: '.process__steps',
			},
		});

	}

	create() {
		super.createComponent();
		this.mdesign = new Mdesign();
		this.mdesign.create();
		this.addEventListeners();
	}

	destroy() {
		super.destroy();
	}
}
